/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  customDateRangeNames,
  defaultDateSelection,
  defaultDateSelectionForAlarms
} from 'app/components/filters/constants';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { types as appTypes, isSignedInSelector } from 'app/redux/app';
import { fetchGuestSubscriber, fetchTopClients } from 'app/redux/clients';
import {
  types as hierarchyTypes,
  selectedPathSelector
} from 'app/redux/hierarchy';
import {
  fetchInventoryDetails,
  fetchInventorySummary
} from 'app/redux/inventory';
import { fetchNetworkDetails, fetchNetworkSummary } from 'app/redux/network';
import {
  getGranularityListForDateRange,
  prefixNavigationPath
} from 'app/utils';
import { eventChannel } from 'redux-saga';
import {
  call,
  getContext,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector,
  updateDateRangeFilter,
  updateGranularityList
} from './';

const mutationObserverConfig = {
  attributes: true,
  childList: true,
  subtree: true
};

function createWindowLocationChannel(window) {
  const [documentBody] = window.document.getElementsByTagName('body');
  let lastKnownLocation = window.location.pathname;
  let observer = null;

  return eventChannel(emit => {
    observer = new MutationObserver(() => {
      const currentLocation = window.location.pathname;
      if (lastKnownLocation !== currentLocation) {
        lastKnownLocation = currentLocation;
        emit(currentLocation);
      }
    });

    observer.observe(documentBody, mutationObserverConfig);
    return () => observer.disconnect();
  });
}

function* watchWindowLocation() {
  const window = yield getContext('window');

  const windowLocationChannel = yield call(createWindowLocationChannel, window);

  try {
    while (true) {
      const locationChanged = yield take(windowLocationChannel);
      const isSignedIn = yield select(isSignedInSelector);
      if (!isSignedIn) {
        break;
      }
      yield call(updateDefaultSelectionForDateRangeFilterSaga, locationChanged);
      yield call(fetchChartDataSaga, locationChanged);
    }
  } catch (err) {
    const reporter = yield getContext('reporter');
    reporter.error('window location error:', err);
  } finally {
    windowLocationChannel.close();
  }
}

function* updateDefaultSelectionForDateRangeFilterSaga(...args) {
  let currentPathname = '';
  const [calledWith] = args;

  if (typeof calledWith === 'string') {
    // our location changed
    currentPathname = calledWith;
  } else {
    // a filter changed
    const window = yield getContext('window');
    currentPathname = window.location.pathname;
  }

  switch (currentPathname) {
    case prefixNavigationPath('/alarms'):
    case prefixNavigationPath('/incidents'): {
      yield put(updateDateRangeFilter(defaultDateSelectionForAlarms));
      break;
    }
    case prefixNavigationPath('/inventory'): {
      yield put(updateDateRangeFilter(defaultDateSelection));
      break;
    }

    default: {
      const dateRangeFilterLabel = yield select(dateRangeFilterLabelSelector);
      if (dateRangeFilterLabel === customDateRangeNames.SHOW_ALL_OPEN_ITEMS) {
        yield put(updateDateRangeFilter(defaultDateSelection));
      }
      break;
    }
  }
}

function* fetchChartDataSaga(...args) {
  let currentPathname = '';
  const [calledWith] = args;

  if (typeof calledWith === 'string') {
    // our location changed
    currentPathname = calledWith;
  } else {
    // a filter changed
    const window = yield getContext('window');
    currentPathname = window.location.pathname;
  }

  const selected = yield select(selectedPathSelector);
  const isPropertyLevel = yield isSpecificNodeType(selected, 'zonename');

  switch (currentPathname) {
    case prefixNavigationPath('/overview'): {
      yield put(fetchInventorySummary({ skipCache: isPropertyLevel }));
      yield put(fetchNetworkDetails());
      yield put(fetchNetworkSummary());
      yield put(fetchGuestSubscriber());
      yield put(fetchTopClients());
      // yield all(
      //   [
      //     fetchInventorySummary,
      //     fetchNetworkDetails,
      //     fetchNetworkSummary,
      //     // fetchClientCount,
      //     fetchGuestSubscriber,
      //     fetchTopClients
      //   ].map(actionCreator => put(actionCreator()))
      // );

      break;
    }
    case prefixNavigationPath('/inventory'): {
      yield put(fetchInventorySummary({ skipCache: isPropertyLevel }));
      yield put(fetchInventoryDetails({ skipCache: isPropertyLevel }));
      break;
    }
    default: {
      // no-op
    }
  }
}

function* updateGranularityFilterDropdownSaga() {
  const dateRange = yield select(dateRangeFilterSelector);
  const granularityList = yield call(getGranularityListForDateRange, dateRange);
  yield put(updateGranularityList(granularityList));
}

export default function createWatchForChangesSaga(types) {
  return [
    takeEvery(
      [
        types.updateVertSSIDFilter,
        types.updateDateRangeFilter,
        hierarchyTypes.hierarchyNodeSelected,
        hierarchyTypes.resetHierarchy
      ],
      fetchChartDataSaga
    ),
    takeLatest(
      [appTypes.userLoginComplete, appTypes.validUserFoundInStorage],
      watchWindowLocation
    ),
    takeEvery(types.updateDateRangeFilter, updateGranularityFilterDropdownSaga)
  ];
}
