/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import Alarms from 'app/modules/alarms';
import Incidents from 'app/modules/incidents';
import Inventory from 'app/modules/inventory';
import Map from 'app/modules/map';
import Overview from 'app/modules/overview';
import Properties from 'app/modules/properties';
import Reports from 'app/modules/reports';
import Search from 'app/modules/search';
import SfdcIncidents  from 'app/modules/sfdc-report';
import {
  appMountedSelector,
  createShowPanelSelector,
  isManualLogOutSelector,
  isSignedInSelector
} from 'app/redux/app';
import { prefixNavigationPath } from 'app/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import { createSelector } from 'reselect';
import LoginRoute from './components/login-route';
import ProtectedRoute from './components/protected-route';

export let navigationPages = [
  {
    key: 'overview',
    path: prefixNavigationPath('/overview'),
    title: 'Overview',
    component: Overview,
    exact: true,
    icon: 'tachometer-alt'
  },
  {
    key: 'properties',
    path: prefixNavigationPath('/properties'),
    title: 'Properties',
    component: Properties,
    exact: true,
    icon: 'building'
  },
  {
    key: 'inventory',
    path: prefixNavigationPath('/inventory'),
    title: 'Inventory',
    component: Inventory,
    icon: 'clipboard-list'
  },
  {
    key: 'map',
    path: prefixNavigationPath('/map'),
    title: 'Map',
    component: Map,
    icon: 'map'
  },
  {
    key: 'reports',
    path: prefixNavigationPath('/reports'),
    title: 'Reports',
    component: Reports,
    icon: 'file-invoice'
  },
  {
    key: 'alarms',
    path: prefixNavigationPath('/alarms'),
    title: 'Alarms',
    component: Alarms,
    icon: 'bell'
  },
  {
    key: 'incidents',
    path: prefixNavigationPath('/incidents'),
    title: 'Incidents',
    component: Incidents,
    icon: 'exclamation-triangle'
  },
  {
    key: 'sfdcincident',
    path: prefixNavigationPath('/incident'),
    title: 'Incidents',
    component: SfdcIncidents,
    icon: 'exclamation-triangle'
  },
  {
    key: Search,
    path: prefixNavigationPath('/search'),
    title: 'Search',
    component: Search,
    icon: 'search'
  }
];

const Routes = ({
  isSignedIn,
  isAppMounted,
  isManualLogOut,
  showAlarms,
  showIncidents,
  showReports,
  showSearch,
  showSfdc
}) => {
  navigationPages = navigationPages
    .filter(p => p.key !== 'alarms' || showAlarms)
    .filter(p => p.key !== 'incidents' || showIncidents)
    .filter(p => p.key !== 'reports' || showReports)
    .filter(p => p.key !== 'search' || showSearch)
    .filter(p => p.key !== 'sfdcincident' || showSfdc);

  return (
    isAppMounted && (
      <Router>
        <Switch>
          <Route
            exact
            path={prefixNavigationPath('/')}
            render={() => <Redirect to={prefixNavigationPath('/login')} />}
          />
          <LoginRoute
            isLoggedIn={isSignedIn}
            path={prefixNavigationPath('/login')}
          />
          {navigationPages.map(p => (
            <ProtectedRoute
              key={p.path}
              isLoggedIn={isSignedIn}
              exact={p.exact}
              path={p.path}
              component={p.component}
              isManualLogOut={isManualLogOut}
            />
          ))}
          <ProtectedRoute path="*" isLoggedIn={isSignedIn} />
        </Switch>
      </Router>
    )
  );
};

Routes.propTypes = {
  isAppMounted: PropTypes.bool,
  isSignedIn: PropTypes.bool,
  isManualLogOut: PropTypes.bool,
  showReports: PropTypes.bool,
  showAlarms: PropTypes.bool,
  showIncidents: PropTypes.bool,
  showSearch: PropTypes.bool,
  showSfdc: PropTypes.bool,
};

const mapStateToProps = createSelector(
  createShowPanelSelector('Reports'),
  createShowPanelSelector('Alarms'),
  createShowPanelSelector('Incidents'),
  createShowPanelSelector('Search'),
  createShowPanelSelector('SfdcIncidents'),
  appMountedSelector,
  isSignedInSelector,
  isManualLogOutSelector,
  (
    showReports,
    showAlarms,
    showIncidents,
    showSearch,
    showSfdc,
    isAppMounted,
    isSignedIn,
    isManualLogOut
  ) => ({
    showReports,
    showAlarms,
    showIncidents,
    showSearch,
    showSfdc,
    isAppMounted,
    isSignedIn,
    isManualLogOut
  })
);

export default connect(mapStateToProps)(Routes);
