import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { Parser } from 'json2csv';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { styleLibrary } from 'app/constants';
import { StatefulTable } from 'app/components/tables';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isArray, isEmpty } from 'lodash';
import styled from 'styled-components';
import {
  caseActivityTableColumns,
  customTableOptions,
  customMuiTableThemeWithCursor
} from './utils';
import {
  fetchSfdcActivity,
  sfdcActivityFetchStateSelector,
  sfdcActivitySelector
} from 'app/redux/incidents';

const CloseButton = styled.button.attrs({
  className: 'btn btn-outline-light my-1 ml-3'
})`
  font-size: ${styleLibrary.fontSizes.titleButton}px;
`;

export class CaseAactivityDetails extends Component {
  componentDidMount = () => {
    const { fetchSfdcActivity, caseid } = this.props;
    fetchSfdcActivity({ caseid });
  };

  componentDidUpdate = prevProps => {
    const { fetchSfdcActivity, caseid } = this.props;
    const { caseid: prevIncidentId } = prevProps;

    const incidentIdChanged = caseid !== '' && prevIncidentId !== caseid;

    if (incidentIdChanged) {
      fetchSfdcActivity({ caseid });
    }
  };

  renderCustomSearch = (searchText, handleSearch) => (
    <div className="d-flex justify-content-start">
      <FaIcon icon="search" classes={['mr-2', 'mt-2']} />
      <label className="sr-only">Search</label>
      <input
        className="p-1"
        id="incident-activity-search"
        onChange={e => handleSearch(e.target.value)}
        placeholder="Search Activity Details..."
        style={{ width: 250 }}
        type="text"
        value={searchText || ''}
      />
    </div>
  );

  handleCSVExport = () => {
    const { sfdcActivity = [], selectedPath } = this.props;
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const customHeader = caseActivityTableColumns.map(c => ({
      label:
        c.name === 'createddatetime' ||
        c.name === 'activitydatetime' ||
        c.name === 'completeddatetime'
          ? isPropertyLevel
            ? c.label
            : `${c.label} (UTC)`
          : c.label,
      value: row => {
        if (c.name === 'description') {
          return row[c.name].trim();
        } else {
          return row[c.name];
        }
      }
    }));
    const parser = new Parser({ fields: customHeader });
    const csv = parser.parse(sfdcActivity);
    const blob = new Blob([csv], {
      type: 'text/csv'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Incidents-Activity.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  render = () => {
    const {
      caseNumber,
      fetchSfdcActivity,
      sfdcActivity = [],
      fetchState,
      toggleActivityTable,
      selectedPath
    } = this.props;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(sfdcActivity) && !isEmpty(sfdcActivity);
    const noDataToShow = !pending && complete && !hasData;

    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const columns = caseActivityTableColumns.map(column => {
      if (
        column.name === 'createddatetime' ||
        column.name === 'activitydatetime' ||
        column.name === 'completeddatetime'
      ) {
        return {
          ...column,
          label: isPropertyLevel ? column.label : `${column.label} (UTC)`
        };
      }
      return column;
    });

    return (
      <BlockContainer classes={['mb-4', 'h-auto']}>
        <TitleBar
          leftChildren={`Activity Details for ${caseNumber}`}
          rightChildren={
            <Fragment>
              {complete && hasData && (
                <StyledExportButton
                  title="Generate CSV"
                  onClick={this.handleCSVExport}
                >
                  <ExportCsvIcon />
                </StyledExportButton>
              )}
              <CloseButton onClick={toggleActivityTable} title="Close">
                <FaIcon icon="times" />
              </CloseButton>
            </Fragment>
          }
        />
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchSfdcActivity}
        >
          {pending ? (
            <LoadingIconPlaceholder position="inherit" />
          ) : noDataToShow || failed ? (
            <NoDataToShow
              message={
                failed
                  ? 'There has been a problem fetching the incident activity'
                  : 'No  incidents activity details available'
              }
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            complete &&
            hasData && (
              <MuiThemeProvider
                theme={createMuiTheme(customMuiTableThemeWithCursor)}
              >
                <StatefulTable
                  tableKey="incident-activity-summary"
                  data={sfdcActivity}
                  columns={columns}
                  options={{
                    ...customTableOptions,
                    customSearchRender: this.renderCustomSearch
                  }}
                />
              </MuiThemeProvider>
            )
          )}
        </FailedFetchStateHandler>
      </BlockContainer>
    );
  };
}

CaseAactivityDetails.propTypes = {
  caseNumber: PropTypes.string,
  caseid: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchSfdcActivity: PropTypes.func.isRequired,
  sfdcActivity: PropTypes.array,
  fetchState: fetchStatePropTypes,
  toggleActivityTable: PropTypes.func
};

const mapStateToProps = createSelector(
  sfdcActivitySelector,
  sfdcActivityFetchStateSelector,
  selectedPathSelector,
  (sfdcActivity, fetchState, selectedPath) => ({
    sfdcActivity,
    fetchState,
    selectedPath
  })
);

const mapDispatchToProps = { fetchSfdcActivity };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseAactivityDetails);
